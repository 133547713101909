<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import zonesTable from './components/zonesTable';
export default {
  page: {
    title: "Zones",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader , zonesTable},
  data() {
    return {
      tableData: [],
      title: "Zones",
      services: [],
      items: [
        {
          text: "Infrastructures",
          href: "javascript:void(0)"
        },
        {
          text: "Zones",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "address", sortable: true },
        { key: "analytic_account", sortable: true },
      ]
    };
  },

  methods: {
    /**
     * Search the table data with search input
     */

    refreshServicesTable(){
      
    },

    
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <zones-table/>
    </div>
  </Layout>
</template>